<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon>mdi-chart-areaspline-variant</v-icon>
        Gelir-Gider (Fatura) Raporu
      </v-card-title>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit('pdf')" ref="form">
          <v-row dense>
            <v-col sm="4">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.date.min"
                :rules="[rules.maxDate(formData.date.min, formData.date.max)]"
                required
              />
            </v-col>

            <v-col sm="4">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.date.max"
                :rules="[rules.minDate(formData.date.max, formData.date.min)]"
                required
              />
            </v-col>

            <v-col sm="4">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
                class="ms-3"
              >
                Göster
              </v-btn>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ms-2"
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    İndir
                    <v-icon>mdi-menu-down-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="handleDownloadClick('ods')">
                    <v-list-item-title>
                      <v-icon>mdi-table</v-icon> ODS
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDownloadClick('xlsx')">
                    <v-list-item-title>
                      <v-icon>mdi-table</v-icon> XLSX
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDownloadClick('pdf')">
                    <v-list-item-title>
                      <v-icon>mdi-text-box-outline</v-icon> PDF
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>

        <iframe
          class="mt-3"
          :src="iframeUrl"
          frameborder="0"
          v-if="iframeUrl"
          style="width: 100%; height: 140vh"
        ></iframe>
      </v-card-text>

      <v-card-text v-else>
        Raporu görebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasDataTable, hasForm } from "@/view/mixins";
import { slugify } from "@/core/helpers";

export default {
  mixins: [hasDataTable, hasForm],
  computed: {
    ...mapGetters(["cluster", "clusterId"]),
  },
  watch: {
    clusterId() {
      this.iframeUrl = null;
    },
  },
  data() {
    const date = new Date();
    const min = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)
    );
    const max = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)
    );

    return {
      iframeUrl: null,
      reportData: null,
      formData: {
        date: {
          min: min.toJSON().split("T")[0],
          max: max.toJSON().split("T")[0],
        },
      },
    };
  },
  methods: {
    handleFormSubmit(format, forceDownload) {
      format = format || "pdf";
      if (this.isLoading || !this.$refs.form.validate() || !this.clusterId) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const params = Object.assign(this.buildSearchParams(this.formData));
      this.iframeUrl = null;
      this.isLoading = true;

      return this.$api
        .query(`reports/accounting-income-expense/${this.clusterId}`, {
          params,
        })
        .then((response) => {
          // eslint-disable-next-line
          this.reportData = Object.assign({}, response.data.data);
          this.reportData.cluster = this.cluster.name;
          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.reportData[index] = response.data.data[index];
            }
          }

          this.reportData.start_date = new Date(
            this.formData.date.min
          ).toLocaleDateString();
          this.reportData.end_date = new Date(
            this.formData.date.max
          ).toLocaleDateString();
          this.reportData.totals = {
            expense_total: this.reportNumberToLocaleFormat(
              format,
              response.data.meta.totals.expense_total
            ),
            income_total: this.reportNumberToLocaleFormat(
              format,
              response.data.meta.totals.income_total
            ),
          };

          for (const index in this.reportData.incomes) {
            const item = this.reportData.incomes[index];
            item.amount = this.reportNumberToLocaleFormat(format, item.amount);

            item.date = new Date(item.date).toLocaleDateString();
            item.index = parseInt(index) + 1;
          }

          for (const index in this.reportData.expenses) {
            const item = this.reportData.expenses[index];
            item.amount = this.reportNumberToLocaleFormat(format, item.amount);

            item.date = new Date(item.date).toLocaleDateString();
            item.index = parseInt(index) + 1;
          }

          return this.reportData;
        })
        .then((reportData) => {
          let fileName = this.cluster.name + "-";
          fileName += new Date(this.formData.date.min).toLocaleDateString();
          fileName += "-GelirGiderFaturaRaporu";
          fileName += "-";
          fileName += new Date(this.formData.date.max).toLocaleDateString();

          if (fileName.length >= 100) {
            fileName = fileName.substring(0, 99);
          }

          const params = {
            file_type: format,
            print_template: "AccountingIncomeExpense",
            receiptData: reportData,
            file_name: slugify(fileName),
          };

          return this.$api.post("print-file", params).then((response) => {
            if (forceDownload) {
              const anchor = document.createElement("a");
              anchor.href = response.data.data.url;
              anchor.target = "_blank";
              anchor.click();
            } else {
              this.iframeUrl = response.data.data.url;
            }
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDownloadClick(format) {
      this.handleFormSubmit(format || "pdf", true);
    },
    reportNumberToLocaleFormat(format, number) {
      // if (format === "xlsx" || format === "ods") {
      //   return number;
      // }

      return this.numberToLocaleFormat(number);
    },
  },
};
</script>
